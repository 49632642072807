


.App-header {
  background-color: #0A2256;
  min-height: 100vh;
  display: flex;

  font-size: calc(10px + 2vmin);
  color: white;
}



